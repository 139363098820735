import * as React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Container, Section, Box, Image } from "./ui"
import { renderOptions, documentToReactComponents, renderRichText } from "../components/renderRichText"
import * as styles from "./about-hero.css"

export interface AboutHeroProps {
  heading: string
  body?: string
  image?: Image
}

export default function AboutHero(props: AboutHeroProps) {
  const image = getImage(props.image.localFile)
  return (
    <Section>
      <Container width="narrow">
        {props.image && (
          <Box padding={4} center>
            <GatsbyImage
              alt={props.image.alt}
              image={image}
              className={styles.aboutHeroImage}
            />
          </Box>
        )}
        {
            documentToReactComponents(
              JSON.parse(props.body.raw)
            )
            &&
            renderRichText(
              props.body, renderOptions()
            )
          }
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment AboutHeroContent on ContentfulAboutHero {
    id
    heading
    body {
      raw
    }
    image {
      id
      alt
      ... on ContentfulAsset {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 800
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`
