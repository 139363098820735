import * as React from "react"
import { graphql, PageProps, HeadFC } from "gatsby"
import Layout from "../components/layout"
import CaseStudyCard from "../components/casestudyCard"
import { CaseStudy } from "../templates/casestudy"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"
import { SEO } from "../components/seo"
import {
  Container,
  FlexList,
  Box,
} from "../components/ui"

interface QueryReturn {
  homepage: {
    id: string
    title: string
    description: string
    blocks: sections.HomepageBlock[]
  }
  portfolio: { casestudies: CaseStudy[] }
}

const Homepage: React.FC<PageProps<QueryReturn>> = ({ data: { homepage, portfolio: { casestudies } } }) => {
  return (
    <Layout {...homepage} styledHeader={true}>
      {homepage.blocks.map((block) => {
        const { id, blocktype, ...componentProps } = block
        const Component = sections[blocktype] || Fallback
        return <Component key={id} {...(componentProps as any)} />
      })}
      <Container width="narrow">
        <Box paddingY={4}>
          <FlexList responsive wrap gap={0} gutter={3} variant="start">
            {casestudies.map((casestudy) => (
              <Box as="li" key={casestudy.id} padding={3} width="third" data-tags={casestudy.tags}>
                <CaseStudyCard {...casestudy} />
              </Box>
            ))}
          </FlexList>
        </Box>
      </Container>
    </Layout>
  )
}

export default Homepage

export const Head: HeadFC<QueryReturn> = ({ data: { homepage }, location }) => (
  <SEO title={homepage.title} description={homepage.description} pathname={location.pathname} />
)

export const query = graphql`
  {
    homepage {
      id
      title
      description
      blocks: content {
        id
        blocktype
        ...HomepageHeroContent
      }
    }
    portfolio: allContentfulCaseStudy(sort: {fields: title, order: ASC}) {
      casestudies: nodes {
        ...CaseStudyContent
      }
    }
  }
`