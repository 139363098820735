import { graphql } from "gatsby"
import * as React from "react"
import {
  Box,
  Container,
  Flex,
  Heading,
  HomepageImage,
  HomepageLink,
  Section,
  Space,
  FlexList,
  Link,
  Text,
  VisuallyHidden
} from "./ui"
import { headerWrapper, homeWrapper } from "./hero.css"

export interface HeroProps {
  image?: HomepageImage
  kicker?: string
  h1: string
  subhead: string
  text: string
  links: HomepageLink[]
}

export default function Hero(props: HeroProps) {
  return (
    <Section>
      <VisuallyHidden>
        <svg>
          <filter id='noiseFilter'>
            <feTurbulence
              type='fractalNoise'
              baseFrequency='0.3'
              numOctaves='30'
              stitchTiles='stitch'/>
          </filter>
        </svg>
      </VisuallyHidden>
      <Container width="narrow">
        <Flex gap={4} variant="responsive">
          <Box width="full">
            <Space size={5} />
            <Heading as="h1">
              {props.h1}
            </Heading>
            <Space size={5} />
          </Box>
        </Flex>
        <Space size={5} />
        <Flex variant="spaceBetween" responsive>
          <Space size={5} />
          <Box center>
            <FlexList>
              {props.links &&
                props.links.map((link) => (
                  <li key={link.id}>
                    <Link to={link.href}>
                      <Text variant="subhead">{link.text}</Text>
                    </Link>
                  </li>
                ))}
            </FlexList>
          </Box>
        </Flex>
      </Container>
    </Section>
  )
}

export const query = graphql`
  fragment HomepageHeroContent on HomepageHero {
    id
    h1: heading
    links {
      id
      href
      text
    }
  }
`
